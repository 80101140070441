@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Inter', sans-serif;
    background-color: #fcfcfc;
    @apply text-sm leading-6 text-gray-700 dark:bg-slate-800 dark:text-slate-400;
}

a {
    @apply text-gray-900 hover:text-black dark:text-slate-300 hover:dark:text-slate-200 border-b border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600;
}

p {
    @apply mb-4;
}

ul {
    @apply list-inside;
}

li {
    @apply list-disc mb-3;
}

li::marker {
    @apply text-gray-300 dark:text-slate-600;
}